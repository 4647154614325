import { Box, Center, Flex, Heading, Text } from "@chakra-ui/react";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { groq } from "next-sanity";
import React, { useState } from "react";

import {
  Header,
  MainButton,
  ScreenContainer,
  TextField,
} from "../src/components";
import { useRKVAuth } from "../src/hooks/rkvLogin";
import { getClient } from "../src/lib/sanity";

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      "common",
      "menu",
      "errors",
      "enterCode",
    ])),
  },
});

const Home = ({ data }) => {
  const router = useRouter();
  const { t } = useTranslation(["common", "errors", "enterCode"]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mobilenumber, setMobilenumber] = useState("");
  const [countrycode, setCountrycode] = useState("+47");
  const { sendMobilenumber, sendOTP } = useRKVAuth();

  const onKeyUp = (event) => {
    if (event.keyCode === 13) {
      handleButtonClick();
    }
  };

  const handleButtonClick = async () => {
    setIsLoading(true);
    let num = countrycode + mobilenumber;
    const status = await sendMobilenumber(num);

    if (status) {
      setIsLoading(false);
      router.push({
        pathname: "/enterCode",
      });
    } else {
      setError(true);
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    setMobilenumber(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountrycode(event.target.value);
  };

  return (
    <div className="wrapper">
      <Head>
        <title>Kurs - Prosjekt Nytt Regjeringskvartal - Statsbygg</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Oswald:wght@300;400;500&display=swap"
          rel="stylesheet"
        />
      </Head>
      <ScreenContainer fullHeight>
        <Flex direction="column" justify="space-between" height="100%">
          <Box>
            <Header showLanguages />
            <Center>
              <Heading fontWeight={400} as="h1">
                {t("title")}
              </Heading>
            </Center>
            <Center pt={2}>
              <Heading textAlign="center" fontWeight={500} as="h2">
                {t("subTitle")}
              </Heading>
            </Center>

            <Box pt={12}>
              <Text fontWeight={600} fontSize="md" align="center">
                {t("paragraph")}
              </Text>
            </Box>
          </Box>
          <Box mt={-8}>
            <TextField
              label={t("inputLabel")}
              placeholder="99 99 99 99"
              button
              isInvalid={error}
              onKeyUp={onKeyUp}
              value={mobilenumber}
              handleChange={handleChange}
              defaultCountry={countrycode}
              handleCountryChange={handleCountryChange}
            />
            {error && (
              <Center>
                <Text pt={2} color="red">
                  {t("errors:loginError")}
                </Text>
              </Center>
            )}
            <Center mt={10}>
              {router.locale === "en" ? (
                <Text textAlign="center">
                  {t("enterCode:paragraph3.0")}{" "}
                  <Link
                    href={`/privacy_pdfs/${router.locale}.pdf`}
                    target="_blank"
                  >
                    {t("enterCode:paragraph3.1")}
                  </Link>{" "}
                  {t("enterCode:paragraph3.2")}.
                </Text>
              ) : (
                <Text textAlign="center">
                  {t("enterCode:paragraph3.0")}{" "}
                  <Link
                    href={`/privacy_pdfs/${router.locale}.pdf`}
                    target="_blank"
                  >
                    {t("enterCode:paragraph3.1")}
                  </Link>
                  .
                </Text>
              )}
            </Center>
          </Box>
          <Center>
            <MainButton
              isLoading={isLoading}
              text={t("buttonLabel")}
              loadingText={t("buttonLoadingLabel")}
              onClick={handleButtonClick}
            />
          </Center>
        </Flex>
      </ScreenContainer>
    </div>
  );
};

export default Home;
