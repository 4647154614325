import React, { useContext, useState } from "react";

const rkvContext = React.createContext();

const RKVAuthProvider = ({ children }) => {
  const [username, setUsername] = useState("");
  const [otp, setOTP] = useState("");
  return (
    <rkvContext.Provider
      value={{
        username,
        setUsername,
        otp,
        setOTP,
      }}
    >
      {children}
    </rkvContext.Provider>
  );
};

const useRKVAuth = () => {
  const { username, setUsername, otp, setOTP } = useContext(rkvContext);
  const apiLoginUrl = process.env.NEXT_PUBLIC_API_LOGINURL;

  const sendMobilenumber = async (mobilenumber) => {
    const result = await fetch(apiLoginUrl + `/auth`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ username: mobilenumber }),
    });
    if (!result.ok) {
      return false;
    }

    const { otp } = await result.json();
    setUsername(mobilenumber);
    sessionStorage.setItem("username", mobilenumber);
    setOTP(otp);

    return true;
  };

  const sendOTP = async (mobilenumber, otp) => {
    const result = await fetch(apiLoginUrl + `/auth/otp`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ username: mobilenumber, otp: otp }),
    });

    if (!result.ok) {
      return false;
    }

    const res = await result.json();
    return res;
  };

  return {
    sendMobilenumber,
    sendOTP,
    username,
    otp,
  };
};

const useRkvFakeCourses = () => {
  const apiLoginUrl = process.env.NEXT_PUBLIC_API_LOGINURL;
  const updateCourse = async (
    mobilenumber,
    orgUnit,
    courseCompletedDate,
    taskReferance
  ) => {
    const result = await fetch(apiLoginUrl + "/hmsreg/course/add", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({
        MobileNr: mobilenumber,
        OrgUnit_ID: orgUnit,
        CourseCompletedDate: courseCompletedDate,
        TaskReferanse: taskReferance,
      }),
    });

    if (!result.ok) {
      return false;
    }

    const res = await result.json();
    console.log(res);
    return res;
  };

  const getHmsregCourses = async (mobilenumber) => {
    const result = await fetch(apiLoginUrl + "/hmsreg/courses", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({
        username: mobilenumber,
      }),
    });

    if (!result.ok) {
      return false;
    }

    const res = await result.json();
    console.log(res);
    return res;
  };

  return {
    updateCourse,
    getHmsregCourses,
  };
};

export { useRKVAuth, RKVAuthProvider, useRkvFakeCourses };
